<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body" v-if="process_steps.length>0">

                    <!-- Checkout Steps -->
                    <div id="progressbarwizard" v-if="active_steps.length>0">
                        <ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
                            <li class="nav-item" v-bind:key="index" v-for="(process_step, index) in active_steps">
                                <a :class="{'active': attestor_step == process_step.order}" @click="setStepIndex(process_step.order, process_step.process_definition_step, process_step.process_step)"
                                   aria-expanded="false" class="nav-link rounded-0" data-toggle="tab" v-bind:href="'#tab'+process_step.process_definition_step">
                                    <i :class="`mdi mdi-numeric-${index+1}-box-multiple font-18`"></i>
                                    <span class="d-none d-lg-block">{{process_step.name}}</span>
                                </a>
                            </li>
                        </ul>

                        <!-- Steps Information -->
                        <div class="tab-content">
                            <div :class="{'active show': clicked_step_index == process_step.order}" :id="'#tab'+process_step.process_definition_step" :key="process_step.process_step"
                                 class="tab-pane" v-for="process_step in active_steps">
                                <a class="p-0 float-right font-weight-semibold mb-3" target="_blank" v-bind:href="$BLOCK_EXPLORER+'/provenance/'+pid">
                                    <i class="mdi mdi-vector-link"></i>&nbsp;View on Blockchain</a>
                                <h4 class="mt-2 mb-2">{{process_step.name}}&nbsp;&nbsp;</h4>
                                <p class="text-muted mb-4" v-if="process_step.description">{{process_step.description}}</p>
                                <p class="text-muted mb-4" v-else></p>
                                <form v-if="(attributes.length>0 || process_step.order == 0) && clicked_step_index==process_step.order">
                                    <template v-if="process_step.status !== 'Attested'">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group row mb-3" v-bind:key="index1" v-for="(a, index1) in attributes">
                                                    <label :for="a.attribute+a.name" class="col-md-3 col-form-label">{{a.name}}</label>
                                                    <div class="col-md-9" v-if="a.name === 'Jute Quality'">
                                                        <multiselect
                                                                :clear-on-select="false"
                                                                :close-on-select="true"
                                                                :hide-selected="true"
                                                                :internal-search="false"
                                                                :options="jute_qualities"
                                                                :selectLabel="''"
                                                                :show-no-results="false"
                                                                @input="setQuality(attributes[index1], a.fact.value)"
                                                                class="form-control multiselect multiselect-sm"
                                                                id="customer"
                                                                label="Select Jute Quality"
                                                                name="customer"
                                                                open-direction="bottom"
                                                                placeholder="Select Jute Quality"
                                                                v-model="a.fact.value">
                                                            <template slot="singleLabel" slot-scope="props">
                                                                {{a.fact.value}}
                                                            </template>
                                                            <template slot="option" slot-scope="props">
                                                                <div class="option__desc">
                                                                    <h5>{{ props.option.fact.value }}</h5>
                                                                </div>
                                                            </template>
                                                            <span slot="noResult">Jute Quality not found</span>
                                                        </multiselect>
                                                        <span class="mg-l-5 tx-13 text-info" v-if="selected_quality">Available quantity of {{selected_quality}} jute is <span
                                                                class="font-weight-bold">{{available_for_input}}</span></span>
                                                    </div>
                                                    <div class="col-md-9" v-else>
                                                        <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                               :name="'attribute_value1'+index1" :readonly="a.readonly" @change="isMrExist(a)" class="form-control" type="text"
                                                               v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Text')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                        <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                               :name="'attribute_value1'+index1" :readonly="a.readonly" class="form-control" type="number"
                                                               v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Float')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                        <select :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :disabled="a.readonly"
                                                                :id="'attribute_value1'+index1" :name="'attribute_value1'+index1" class="form-control custom-select"
                                                                v-if="a.values && a.values.length >0 && process_step.status !== 'Attested'" v-model="a.fact.value"
                                                                v-validate="{ required: a.required }">
                                                            <option :value="null" selected>Select {{a.name}}</option>
                                                            <option :key="val" :value="val" v-for="val in a.values">
                                                                {{val}}
                                                            </option>
                                                        </select>
                                                        <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                               :name="'attribute_value1'+index1" :readonly="a.readonly" class="form-control" type="date"
                                                               v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Date' || a.fact.type === 'DateTime')" v-model="a.fact.value"
                                                               v-validate="{ required: a.required }">
                                                        <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                               :name="'attribute_value1'+index1" :readonly="a.readonly" @input="calculate(a)" class="form-control" type="number"
                                                               v-if="(!a.values || a.values.length === 0) && a.fact.type === 'Integer'" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                        <div class="invalid-feedback" v-if="submitted && errors.has('attribute_value1'+index1)">
                                                            {{errors.first('attribute_value1'+index1) }}
                                                        </div>
                                                        <div class="mt-2" v-else-if="(!a.values || a.values.length === 0) && a.fact.type === 'Bool'">
                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                <input :class="{'is-invalid': submitted && errors.has('customRadio3'+index1)}" :id="'customRadio3'+index1" :readonly="a.readonly"
                                                                       class="custom-control-input" name="customRadio1" type="radio" v-validate="{ required: a.required }" value="true">
                                                                <div class="invalid-feedback" v-if="submitted && errors.has('customRadio3'+index1)">
                                                                    {{
                                                                    errors.first('customRadio3'+index1) }}
                                                                </div>
                                                                <label :for="'customRadio3'+index1" class="custom-control-label">Yes</label>
                                                            </div>
                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                <input :class="{'is-invalid': submitted && errors.has('customRadio4'+index1)}" :id="'customRadio4'+index1" :readonly="a.readonly"
                                                                       class="custom-control-input" name="customRadio1" type="radio" v-validate="{ required: a.required }" value="false">
                                                                <div class="invalid-feedback" v-if="submitted && errors.has('customRadio4'+index1)">
                                                                    {{
                                                                    errors.first('customRadio4'+index1) }}
                                                                </div>
                                                                <label :for="'customRadio4'+index1" class="custom-control-label">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row" v-if="process_step.order===0 && qualities.length>0">
                                                    <label class="col-md-3 col-form-label">Jute Bales in {{selected_mr}}</label>
                                                    <div class="table-responsive col-md-9">
                                                        <table class="table table-bordered table-centered mb-0">
                                                            <thead>
                                                            <tr>
                                                                <th>Quality</th>
                                                                <th>Quantity</th>
                                                                <th>Issued to</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-bind:key="q_index" v-for="(val, q_index) in qualities">
                                                                <td>{{val.quality.fact.value}}</td>
                                                                <td>{{val.quantity.fact.value}}</td>
                                                                <td>{{val.origin.fact.value}}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="form-group row" v-if="jute_usages.length>0">
                                                    <label class="col-md-3 col-form-label">Usages</label>
                                                    <div class="table-responsive col-md-9">
                                                        <table class="table table-bordered table-centered mb-0">
                                                            <thead>
                                                            <tr>
                                                                <th>Batch No</th>
                                                                <th>Quality</th>
                                                                <th>Production Line</th>
                                                                <th>No of Bales</th>
                                                                <th>Weight (kg)</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-bind:key="index" v-for="(key, index) in jute_usages">
                                                                <td>{{key.name}}</td>
                                                                <td v-if="key.attributes.length>0">{{key.attributes[0][2].fact.value}}</td>
                                                                <td v-if="key.attributes.length>1">{{key.attributes[1][2].fact.value}}</td>
                                                                <td v-if="key.attributes.length>2">{{key.attributes[2][2].fact.value}}</td>
                                                                <td v-if="key.attributes.length>3">{{key.attributes[3][2].fact.value}}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-sm-6">
                                                <router-link :to="{name: 'batches'}" class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                                                    <i class="mdi mdi-arrow-left"></i> Back to Batches
                                                </router-link>
                                            </div>
                                            <div class="col-sm-6" v-if="attestor_step === process_step.order">
                                                <div class="text-sm-right">
                                                    <a :class="{'not-active': updating}" @click="![2, 3, 4].includes(process_step.order) ? updateStep(): updateInspection()" class="btn btn-primary"
                                                       href="javascript:void(0)">
                                                        <i class="mdi mdi-content-save mr-1"></i> Save as Draft </a>
                                                    <a :class="{'not-active': attesting}" @click="![2, 3, 4].includes(process_step.order) ? attest() : attestInspection()" class="ml-1 btn btn-success"
                                                       href="javascript:void(0)">
                                                        <i class="mdi mdi-arrow-right-bold-circle-outline mr-1"></i>
                                                        Confirm </a>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="row">
                                            <div class="col-12">
                                                <div :key="a.name" class="form-group row mb-3" v-for="(a, index1) in attributes">
                                                    <template>
                                                        <label :for="a.attribute+a.name" class="col-md-3 col-form-label">{{a.name}}</label>
                                                        <div class="col-md-9">
                                                            <input class="form-control" readonly type="text" v-model="a.fact.value">
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-sm-6">
                                                <router-link :to="{name: 'batches'}" class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                                                    <i class="mdi mdi-arrow-left"></i> Back to Batches
                                                </router-link>
                                            </div> <!-- end col -->
                                            <div class="col-sm-6" v-if="process_step.status === 'Attested'">
                                                <p class="text-sm-right text-muted font-weight-semibold">Signed on&nbsp;<small class="text-muted">{{process_step.attested_at | shorttimestamp}}</small>
                                                </p>
                                            </div>
                                        </div> <!-- end row -->
                                    </template>
                                </form>
                                <div class="d-flex justify-content-center mb-3" v-else-if="!removeLoader">
                                    <div class="spinner-border avatar-md text-primary" role="status"></div>
                                </div>
                                <div class="m-5" v-else-if="removeLoader && !attesting">
                                    <h4 class="card-title text-muted text-center mt-lg-4">No records found.</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-12 text-center alert alert-warning alert-dismissible fade show"
                         role="alert"
                         v-else>
                        <strong>Warning - </strong> waiting for signer(s) of the previous step(s) to add
                        information and signing !
                    </div>

                </div> <!-- end card-body-->
            </div> <!-- end card-->
        </div> <!-- end col -->
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import EventBus from "../../../../event-bus";
    import Multiselect from "vue-multiselect";

    export default {
        name: "ProductionSteps",
        props: ['batchid'],
        components: {Multiselect},
        computed: {
            ...mapGetters(["currentUser"]),
            active_steps: function () {
                return this.process_steps.filter(process_step => process_step.order <= this.current_step && (this.attestor_step == this.current_step || process_step.order < this.current_step));
            }
        },
        data() {
            return {
                pid: null,
                submitted: false,
                current_step: 0,
                isCurrentStep: true,
                clicked_step_index: 0,
                process_steps: [],
                attestor_step: -1,
                definition_attributes: [],
                attributes: [],
                attestors: [],
                attestor_process_definition_step: null,
                step_to_update: null,
                user: {
                    mill_id: null
                },
                updating: false,
                attesting: false,
                truck_no: null,
                gross_weight: null,
                removeLoader: false,
                jute_qualities: [],
                mrs: ['MR #100', 'MR #101', 'MR #102'],
                qualities: [],
                attributes_bkp: [],
                mr_processes: [],
                process_status: null,
                selected_quality: null,
                selected_mr: null,
                jute_usages: [],
                available_for_input: 0,
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await this.getProcess();
                await this.getProcessSteps();
                if (this.attestor_step == this.current_step) {
                    await this.getDefinitionAttributes(this.attestor_process_definition_step);
                    this.isCurrentStep = true;
                    await this.getAttributes(this.step_to_update);

                } else if (this.active_steps.length > 0) {
                    this.isCurrentStep = false;
                    await this.getAttributes(this.active_steps[0].process_step);
                }
                // await this.getMrs();
            },
            async getProcess() {
                try {
                    let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                        params: {
                            process_type: 'Batch',
                            page: 0,
                            per_page: 100,
                            search: this.batchid
                        }
                    });
                    this.current_step = data.processes[0].current_step;
                    this.pid = data.processes[0].process;
                    this.process_status = data.processes[0].status;
                } catch (e) {
                    console.error(e);
                }
            },
            async getProcessSteps() {
                try {
                    let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes/${this.pid}/steps`, {
                        params: {
                            process_type: 'Batch',
                            page: 0,
                            per_page: 100
                        }
                    });
                    this.process_steps = data.process_steps;
                    if (this.process_status !== 'Completed') {
                        this.process_steps.forEach(step => {
                            let found = step.attestors.find(sa => this.currentUser.did === sa.did && (step.status === 'Created' || step.status === 'Attested'));
                            if (found && found.did) {
                                this.attestor_process_definition_step = step.process_definition_step;
                                this.step_to_update = step.process_step;
                                this.attestor_step = step.order;
                                this.clicked_step_index = step.order;
                            }
                        });
                    } else {
                        this.attestor_step = this.clicked_step_index = 0;
                    }
                } catch (e) {
                    console.error(e);
                    this.$toastr.e('Failed to get steps', 'Failed');
                }
            },
            async getDefinitionAttributes(definition_step_id) {
                if (definition_step_id) {
                    try {
                        let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/process_definition_step/${definition_step_id}/attributes`, {
                            params: {
                                process_type: 'Batch',
                                page: 0,
                                per_page: 100
                            }
                        });
                        this.definition_attributes = data.process_definition_step_attributes.map(a => {
                            let attribute = {
                                ...a,
                                fact: {
                                    type: a.fact_type,
                                    value: null
                                },
                                readonly: false,
                                values: a.options && typeof a.options === 'string' ? a.options.split(';') : []
                            };
                            return attribute;
                        });
                    } catch (e) {
                        console.error(e);
                        this.$toastr.e('Failed to get attributes', 'Failed');
                    }
                }
            },
            async getAttributes(process_step_id) {
                if (process_step_id) {
                    try {
                        this.removeLoader = false;
                        let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/process/${this.pid}/process_step/${process_step_id}/attributes`, {
                            params: {
                                process_type: 'Batch',
                                page: 0,
                                per_page: 100,
                                sort_by: 'created',
                                order: 'ASC'
                            }
                        });
                        this.attributes_bkp = data.attributes;
                        if (this.isCurrentStep) {
                            this.definition_attributes.forEach(da => {
                                let found = data.attributes.find(a => da.name === a.name);
                                if (found && found.attribute) {
                                    da.attribute = found.attribute;
                                    da.fact.value = found.fact.value;
                                }
                            });
                            this.attributes = this.definition_attributes;
                        } else {
                            this.attributes = data.attributes;
                        }
                    } catch (e) {
                        console.error(e);
                        this.$toastr.e('Failed to get attributes', 'Failed');
                    } finally {
                        this.removeLoader = true;
                    }
                }

            },
            async setStepIndex(i, process_definition_step, process_step) {
                this.attributes = [];
                this.clicked_step_index = i;
                if (this.current_step == i) {
                    await this.getDefinitionAttributes(process_definition_step);
                    this.isCurrentStep = true;
                    await this.getAttributes(process_step);
                } else {
                    this.isCurrentStep = false;
                    await this.getAttributes(process_step);
                }
            },
            getDatatypeForUpdate(type, val) {
                switch (type) {
                    case "Text":
                    case "Float":
                        return 'String';
                    case "Integer":
                        return 'U128';
                    case "Yes/No":
                        return "Bool";
                    default:
                        return type;
                }
            },
            async updateStep() {
                this.submitted = false;
                let attributes = this.attributes;
                this.attributes = [];
                if (!this.attesting) {
                    this.removeLoader = false;
                }
                let update_attributes = attributes.filter(a => a.fact.value && a.attribute)
                        .map(ma => (
                                {
                                    attribute: ma.attribute,
                                    name: ma.name,
                                    type: this.getDatatypeForUpdate(ma.fact.type, ma.fact.value ? ma.fact.value : null),
                                    value: ma.fact.value,
                                }
                        ));

                let add_attributes = attributes.filter(a => a.fact.value && !a.attribute)
                        .map(ma => (
                                {
                                    name: ma.name,
                                    type: this.getDatatypeForUpdate(ma.fact.type, ma.fact.value ? ma.fact.value : null),
                                    value: ma.fact.value,
                                }
                        ));
                try {
                    this.updating = true;
                    await this.$http.patch(`mill/${this.currentUser.mill_id}/processes/${this.pid}/steps/${this.step_to_update}`, {
                        process_type: 'Batch',
                        with_did: this.currentUser.did,
                        add_attributes: add_attributes,
                        update_attributes: update_attributes,
                    });
                    if (!this.attesting) {
                        await this.getDefinitionAttributes(this.attestor_process_definition_step);
                        this.isCurrentStep = true;
                        await this.getAttributes(this.step_to_update);
                        this.$toastr.s('Attribute(s) successfully updated', 'Success');
                    }
                } catch (e) {
                    this.$toastr.e('Process step updating failed', 'Failed');
                } finally {
                    this.updating = false;
                    this.removeLoader = true;
                }
            },
            async attest() {
                this.submitted = true;
                let valid = await this.$validator.validate();
                if (valid) {
                    try {
                        EventBus.$emit('openLoader');
                        this.attesting = true;
                        await this.updateStep();
                        await this.$http.post(`mill/${this.currentUser.mill_id}/processes/${this.pid}/steps/${this.step_to_update}/attest`, {
                            process_type: 'Batch',
                            process_definition_step_index: this.step_to_update
                        });
                        await this.getProcess();
                        await this.getProcessSteps();
                        await this.getDefinitionAttributes(this.attestor_process_definition_step);
                        this.isCurrentStep = true;
                        await this.getAttributes(this.step_to_update);
                        this.$toastr.s('Successfully signed', 'Success');
                    } catch (e) {
                        this.$toastr.e('Attestation failed', 'Failed');
                    } finally {
                        EventBus.$emit('closeLoader');
                        this.attesting = false;
                    }
                }
            },
            addQuality(index) {
                index++;
                this.qualities.push(
                        {
                            mr: {
                                name: `MR ${index + 1}`,
                                fact: {
                                    type: 'Text',
                                    value: null
                                }
                            },
                            quality: {
                                name: `Quality_MR ${index + 1}`,
                                fact: {
                                    type: 'Text',
                                    value: null
                                }
                            },
                            quantity: {
                                name: `Quantity_MR ${index + 1}`,
                                fact: {
                                    type: 'Integer',
                                    value: null
                                }
                            },
                            process: {
                                name: `Process_MR ${index + 1}`,
                                fact: {
                                    type: 'Integer',
                                    value: null
                                }
                            }
                        }
                );
            },
            async removeQuality(index, val) {
                if (val.quality.attribute && val.quantity.attribute) {
                    try {
                        EventBus.$emit('openLoader');
                        await this.$http.patch(`mill/${this.currentUser.mill_id}/processes/${this.pid}/steps/${this.step_to_update}`, {
                            process_type: 'Batch',
                            with_did: this.currentUser.did,
                            remove_attributes: [
                                {
                                    attribute: val.mr.attribute,
                                    name: val.mr.name,
                                    type: this.getDatatypeForUpdate(val.mr.fact.type, val.mr.fact.value ? val.mr.fact.value : null),
                                    value: val.mr.fact.value
                                },
                                {
                                    attribute: val.quality.attribute,
                                    name: val.quality.name,
                                    type: this.getDatatypeForUpdate(val.quality.fact.type, val.quality.fact.value ? val.quality.fact.value : null),
                                    value: val.quality.fact.value
                                },
                                {
                                    attribute: val.quantity.attribute,
                                    name: val.quantity.name,
                                    type: this.getDatatypeForUpdate(val.quantity.fact.type, val.quantity.fact.value ? val.quantity.fact.value : null),
                                    value: val.quantity.fact.value
                                }
                            ]
                        });
                        await this.getAttributes();
                        await this.updateStep();
                    } catch (e) {
                        this.$toastr.e('Remove attribute failed', 'Failed');
                    } finally {
                        EventBus.$emit('closeLoader');
                    }
                }
                this.qualities.splice(index, 1);
            },
            async isMrExist(attribute) {
                if (attribute.name === 'MR Number') {
                    this.selected_mr = attribute.fact.value.toString();
                    let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                        params: {
                            process_type: 'Consignment',
                            page: 0,
                            per_page: 100,
                            attribute_name: 'MR Number',
                            attribute_value: attribute.fact.value.toString(),
                            attribute_value_exact: true,
                            fetch: {
                                attributes: [{
                                    attribute_name: "Net Weight (kg)",
                                    attribute_step: 4
                                }, {
                                    attribute_name: "Origin (Mukam Area)",
                                    attribute_step: 0
                                }]
                            }
                        }
                    });
                    if (!data.processes || data.processes.length === 0) {
                        this.$toastr.w('Could not find the MR', 'Warning');
                    } else {
                        this.qualities = [];
                        let reply = await this.$http.get(`mill/${this.currentUser.mill_id}/processes/${data.processes[0].process}/steps`, {
                            params: {
                                process_type: 'Consignment',
                                page: 0,
                                per_page: 100
                            }
                        });
                        let last_step = reply.data.process_steps[reply.data.process_steps.length - 1].process_step;
                        let reply2 = await this.$http.get(`mill/${this.currentUser.mill_id}/process/${data.processes[0].process}/process_step/${last_step}/attributes`, {
                            params: {
                                process_type: 'Consignment',
                                page: 0,
                                per_page: 100
                            }
                        });
                        let q = reply2.data.attributes.filter(a => a.name.indexOf('Jute Quality') === 0);
                        this.jute_qualities = q;
                        let r = reply2.data.attributes.filter(a => a.name.indexOf('No. of Bales') === 0);
                        let s = reply2.data.attributes.filter(a => a.name.indexOf('Godown Name/Issue to Mill') === 0);
                        if (q.length > 0 && r.length > 0 && q.length === r.length) {
                            this.qualities = [];
                            q.forEach((val, index) => {
                                this.qualities.push({
                                    quality: val,
                                    quantity: r[index],
                                    origin: s[index]
                                })
                            })
                        }
                    }
                }

            },
            async getMrs() {
                let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                    params: {
                        process_type: 'Consignment',
                        page: 0,
                        per_page: 100,
                        fetch: {
                            attributes: [{
                                attribute_name: "MR Number",
                                attribute_step: 4
                            }]
                        },
                    }
                });
                let mrs = data.processes.filter(p => p.attributes.length > 0).map(p => ({
                    process: p.process,
                    name: p.name,
                    mr: p.attributes.length > 0 ? p.attributes[0][2] : null
                }))
                console.log(mrs);
            },

            async setQuality(arr_ref, q) {
                this.selected_quality = q.fact.value;
                arr_ref.fact.value = q.fact.value;

                let reply3 = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                    params: {
                        process_type: 'Batch',
                        page: 0,
                        per_page: 100,
                        attribute_name: 'MR Number',
                        attribute_value: this.selected_mr,
                        attribute_value_exact: true,
                        fetch: {
                            attributes: [
                                {
                                    attribute_name: "Jute Quality",
                                    attribute_step: 0
                                },
                                {
                                    attribute_name: "Batch Type",
                                    attribute_step: 0
                                },
                                {
                                    attribute_name: "Quantity",
                                    attribute_step: 0
                                },
                                {
                                    attribute_name: "Weight (kg)",
                                    attribute_step: 0
                                }
                            ]
                        },
                    }
                });
                this.jute_usages = reply3.data.processes.filter(p => p.attributes[0][2].fact.value === this.selected_quality);
                let used = 0;
                this.jute_usages.forEach(ju => {
                    if (ju.attributes.length > 2) {
                        used += Number(ju.attributes[2][2].fact.value)
                    }
                })
                let found_q = this.qualities.find(q => q.quality.fact.value === this.selected_quality);
                this.available_for_input = Math.abs(Number(found_q.quantity.fact.value) - Number(used));
            },
        }
    }
</script>

<style scoped>

</style>